// Add the drawer module.
app.dropdown = function (suppliedSettings) {
  var settings = {};

  var dropdown = function (suppliedSettings) {
    // Sanity check for supplied Settings.
    suppliedSettings = suppliedSettings || {};

    // Default settings.
    var defaultSettings = {
      speedOpen: 50,
      speedClose: 50,
      visibleClass: 'dropdown-is-visible',
      selectorTarget: '[data-dropdown-target]',
      selectorTrigger: '[data-dropdown-trigger]'
    };

    // Applied settings.
    settings = {
      ...defaultSettings,
      ...suppliedSettings
    };

    // Inits & Event Listeners
    var dropdowns = document.querySelectorAll(settings.selectorTrigger),
    i;

    for (i = 0; i < dropdowns.length; ++i) {
      dropdowns[i].addEventListener('click', clickHandler, false);
      dropdowns[i].addEventListener('mouseover', mouseOverHandler, false);
    }

    document.addEventListener('keydown', keydownHandler, false);
  };

  var hideAllDropdowns = function() {
    // Find all possible drawers
    var dropdowns = document.querySelectorAll(settings.selectorTarget),
    i;

    // Find active drawers and close them when escape is clicked
    for (i = 0; i < dropdowns.length; ++i) {
      if (dropdowns[i].classList.contains(settings.visibleClass)) {
        closeDropdown(dropdowns[i]);
      }
    }
  };

  var openDropdown = function (target) {
    // Hide any open dropdowns.
    hideAllDropdowns();

    // Show the target dropdown.
    setTimeout(function () {
      target.classList.add(settings.visibleClass);
    }, settings.speedOpen);
  };

  var closeDropdown = function (target) {
    // Make it hidden
    setTimeout(function () {
      target.classList.remove(settings.visibleClass);
    }, settings.speedClose);
  };

  // Click Handler
  var clickHandler = function (event) {
    var target = event.target.closest(settings.selectorTarget);

    if (!target) {
      return;
    }

    var isOpen = target.classList.contains(settings.visibleClass);

    if (isOpen) {
      closeDropdown(target);
    }
    else {
      openDropdown(target);
    }

    event.preventDefault();
  }

  // Mouse Over Handler
  var mouseOverHandler = function (event) {
    hideAllDropdowns();
  }

  // Keydown Handler, handle Escape button
  var keydownHandler = function (event) {
    if (event.key === 'Escape' || event.keyCode === 27) {
      hideAllDropdowns();
    }
  };

  window.addEventListener('DOMContentLoaded', function (event) {
    dropdown(suppliedSettings);
  });

  return {
    hideAllDropdowns: hideAllDropdowns
  };
}();
